<template>
  <div :style="$vuetify.breakpoint.mdAndUp ? 'height:100%;' : ''">
    <v-dialog content-class="daily-rewards-dialog" persistent :value="dailyRewardsDialogShow" style="margin:10px" :max-width="440">
      <v-card class="daily-rewards-card">
        <v-row justify="space-between" no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header pt-5 px-5' : 'dialog-header pt-8 px-5'">
          <v-col cols="11">
            <label class="dialog-title pt-3 ma-0 text-capitalize d-block full-width">{{ $t(`label.dailyCheckIn`) }}</label>
            <label class="dialog-subtitle">{{ $t(`label.checkIn7Days`) }}</label>
          </v-col>
          <v-col cols="1" class="d-flex align-center justify-center pt-3">
            <v-icon :class="'dialog-close-icon'" @click="closeDailyRewardsDialog">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'px-5 py-3' : 'px-5 py-6'">
          <v-col cols="12">
            <v-divider class="dialog-divider"></v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters class="d-block dialog-row" v-if="dailyRewardsDialogShow">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="mb-8" :class="$vuetify.breakpoint.mdAndDown ? 'px-5' : 'px-5'">
              <v-card color="footer_color text-center" elevation="0" class="">
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="auto" class="text-right">
                    <img src="/static/svg/coin-event.svg" class="mt-1" height="24" width="24" />
                  </v-col>
                  <v-col cols="auto" class="pl-2 text-left" style="font-size:14px">
                    {{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" class="mb-8" :class="$vuetify.breakpoint.mdAndDown ? 'px-5' : 'px-5'">
              <v-row no-gutters justify="end">
                <v-col v-for="(r, index) in dailyRewards" :key="index">
                  <v-row no-gutters class="daily-coins-progress">
                    <v-col>
                      <v-row no-gutters>
                        <v-col cols="12" class="text-center">
                          {{ r.coins }}
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <img :src="getCoinsImg(r)" />
                        </v-col>
                        <v-col cols="12" class="text-center">
                          {{ days[r.day] }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- <v-col>
                            <v-divider v-if="index !== dailyRewards.length - 1" class="coin-divider"></v-divider>
                          </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8" class="mb-5" :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : 'px-5'">
              <app-button class="dialog-button" :action="this.checkInDay" :title="$t(`button.checkInToday`)"></app-button>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-dialog>
    <v-card class="desktop_referral_card hidden-sm-and-down afrLoginContainer">
      <v-card-title class="d-none"></v-card-title>
      <div class="afterLogin">
        <div>
          <v-row no-gutters class="px-4" style="position:relative;">
            <v-row no-gutters>
              <v-col cols="12" class="pa-0">
                <v-img :src="`/static/image/referral/reward_banner_desktop.jpg`" class="newReferralBanner-desktop" :aspect-ratio="87 / 16">
                  <div class="rewardStoreBannerText">
                    <v-row no-gutters>
                      <v-col cols="7" class="pa-0">
                        <h1>{{ $t(`label.rewardStoreBannerTitle`) }}</h1>
                        <span>{{ $t(`label.rewardStoreBannerDesc`) }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-img>
              </v-col>
            </v-row>
            <v-row no-gutters class="subpages pt-3" v-if="isLoggedIn">
              <v-col cols="12" class="pa-0">
                <v-row no-gutters class="py-3 card-row justify-space-between">
                  <v-col cols="3" class="pa-0 d-flex rewardCoinsBorder">
                    <v-col cols="3" class="py-2 px-0 rewardCoinsContainer">
                      <img src="/static/svg/rewardCoins.svg" class="left-panel-icon" />
                    </v-col>
                    <v-col cols="9" class="py-2 px-2 rewardCoinsTextContainer">
                      <span class="">{{ memberInfo ? memberInfo.rewardBalance : '' }}</span>
                    </v-col>
                  </v-col>
                  <v-col cols="3" class="pa-0 d-flex">
                    <v-col cols="12" class="py-2 px-0 d-flex justify-end goRewardHistory" @click="goToRewardHistory">
                      <span>{{ $t(`label.rewardHistory`) }} ></span>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-left">
                <v-btn
                  plain
                  class="reward-tab"
                  :class="this.$route.name === routeName.REWARD_STORE_ITEM ? 'active-btn' : 'inactive-btn'"
                  :to="{ name: routeName.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } }"
                >
                  <v-img :src="`/static/image/icon/rewardStoreIcon_desktop.svg`" height="20" width="20" class=""></v-img>
                  <span class="pl-2">{{ $t(`mobileMenuTitle.rewardStore`) }}</span>
                </v-btn>
                <v-btn plain class="reward-tab inactive-btn" @click="openDailyRewardsDialog()">
                  <v-img :src="`/static/image/icon/dailyCheckInIcon_desktop.svg`" height="23" width="25" class=""></v-img>
                  <span class="pl-2">{{ $t(`label.checkin`) }}</span>
                </v-btn>
                <v-btn plain class="reward-tab" :class="this.$route.name === routeName.WOF ? 'active-btn' : 'inactive-btn'" :to="{ name: routeName.WOF, params: { lang: this.$route.params.lang } }">
                  <v-img :src="`/static/image/icon/wofIcon_desktop.svg`" height="20" width="15" class=""></v-img>
                  <span class="pl-2">{{ $t(`label.wofv3`) }}</span>
                </v-btn>
                <!--                <v-btn-->
                <!--                  plain-->
                <!--                  class="reward-tab"-->
                <!--                  :class="this.$route.name === routeName.REWARDS ? 'active-btn' : 'inactive-btn'"-->
                <!--                  :to="{ name: routeName.REWARDS, params: { lang: this.$route.params.lang } }"-->
                <!--                >-->
                <!--                  <v-img :src="`/static/image/icon/dailyCheckInIcon_desktop.svg`" height="20" width="22" class=""></v-img>-->
                <!--                  <span class="pl-2">{{ $t(`mobileMenuTitle.rewards`) }}</span>-->
                <!--                </v-btn>-->
                <!--                <v-btn-->
                <!--                  plain-->
                <!--                  class="reward-tab"-->
                <!--                  :class="this.$route.name === routeName.CARD_MANIA ? 'active-btn' : 'inactive-btn'"-->
                <!--                  :to="{ name: routeName.CARD_MANIA, params: { lang: this.$route.params.lang } }"-->
                <!--                >-->
                <!--                  <v-img :src="`/static/image/icon/cardManiaIcon_desktop.svg`" height="20" width="20" class=""></v-img>-->
                <!--                  <span class="pl-2">{{ $t(`label.cardMania`) }}</span>-->
                <!--                </v-btn>-->
                <!--                <v-btn-->
                <!--                  plain-->
                <!--                  class="reward-tab"-->
                <!--                  :class="this.$route.name === routeName.CARD_MANIA ? 'active-btn' : 'inactive-btn'"-->
                <!--                  :to="{ name: routeName.CARD_MANIA, params: { lang: this.$route.params.lang } }"-->
                <!--                >-->
                <!--                  <span>{{ $t(`label.cardMania`) }}</span>-->
                <!--                </v-btn>-->
              </v-col>
            </v-row>
          </v-row>
        </div>
      </div>
      <v-card-subtitle v-if="!isLoggedIn">
        <div v-if="!isLoggedIn">
          <v-row class=" px-4 pt-2" no-gutters>
            <v-col cols="12">
              <v-btn :class="'primary-button mr-6 newMobileSecondary subtitle-1 full-width white--text pa-2 theme-button embedded-button '" @click="goToRegisterPage">
                {{ $t(`label.signUp`) }}
              </v-btn>
              <v-btn height="auto" @click="goToLoginPage" class="embedded-button primary-button buttonPrimary embedded-login-button pa-2 black--text subtitle-1">
                {{ $t(`button.login`) }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-subtitle>
      <v-card-text class="pa-0 pt-1">
        <div class="" style="min-height: 440px;">
          <v-row no-gutters>
            <v-col cols="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="justify-space-around hidden-md-and-up">
      <v-row class="d-flex justify-space-between" no-gutters style="background-color: #F3F5F6;">
        <v-col cols="3" class="pa-0 d-flex justify-center align-center">
          <span class="rewardText">{{ $t(`label.rewards`) }}</span>
        </v-col>
        <v-col cols="3" class="pa-0 d-flex justify-end">
          <v-img :src="`/static/image/icon/rewardIcon.png`" height="100px" class=""></v-img>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" v-if="isLoggedIn">
        <v-row no-gutters class="mobileRewardContainer pa-3 ma-3">
          <v-col cols="12" class="pa-0">
            <v-row no-gutters class="card-row justify-space-between">
              <v-col cols="6" class="py-2 px-0 d-flex">
                My reward coins
              </v-col>
              <v-col cols="6" class="pa-0 d-flex">
                <v-col cols="12" class="py-2 px-0 d-flex justify-end goRewardHistory" @click="goToRewardHistory">
                  <span>{{ $t(`label.rewardHistory`) }} ></span>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row no-gutters class=" card-row justify-space-between">
              <v-col cols="7" class="pa-0 d-flex rewardCoinsBorderMobile">
                <v-col cols="3" class="py-2 px-0 rewardCoinsContainerMobile">
                  <img src="/static/svg/rewardCoins.svg" width="30" height="30" />
                </v-col>
                <v-col cols="9" class="py-2 px-2 rewardCoinsTextContainerMobile">
                  <span class="">{{ memberInfo ? memberInfo.rewardBalance : '' }}</span>
                </v-col>
              </v-col>
              <!--            <v-col cols="4" class="pa-0 d-flex">-->
              <!--              <v-col cols="12" class="py-2 px-0 d-flex justify-end" @click="goToRewardHistory">-->
              <!--                Rewards History >-->
              <!--              </v-col>-->
              <!--            </v-col>-->
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" class="text-left">
          <div class="scrollable-container pa-3 ma-3">
            <v-btn
              plain
              class="reward-tab mr-2 inactive-btn"
              :class="this.$route.name === routeName.REWARD_STORE_ITEM ? 'active-btn' : 'inactive-btn'"
              :to="{ name: routeName.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } }"
            >
              <v-img :src="`/static/image/icon/rewardStoreIcon.svg`" height="20" width="20" class=""></v-img>
              <span class="pl-2">{{ $t(`mobileMenuTitle.rewardStore`) }}</span>
            </v-btn>
            <v-btn plain class="reward-tab mr-2 inactive-btn" @click="openDailyRewardsDialog()">
              <v-img :src="`/static/image/icon/dailyCheckInIcon.svg`" height="20" width="20" class=""></v-img>
              <span class="pl-2">{{ $t(`label.checkin`) }}</span>
            </v-btn>
            <v-btn
              plain
              class="reward-tab mr-2 inactive-btn"
              :class="this.$route.name === routeName.WOF ? 'active-btn' : 'inactive-btn'"
              :to="{ name: routeName.MWOF, params: { lang: this.$route.params.lang } }"
            >
              <v-img :src="`/static/image/icon/wofIcon.svg`" height="20" width="20" class=""></v-img>
              <span class="pl-2">{{ $t(`label.wofv3`) }}</span>
            </v-btn>
            <!--            <v-btn-->
            <!--              plain-->
            <!--              class="reward-tab"-->
            <!--              :class="this.$route.name === routeName.REWARDS ? 'active-btn' : 'inactive-btn'"-->
            <!--              :to="{ name: routeName.REWARDS, params: { lang: this.$route.params.lang } }"-->
            <!--            >-->
            <!--              <v-img :src="`/static/image/icon/dailyCheckInIcon.svg`" height="20" width="20" class=""></v-img>-->
            <!--              <span class="pl-2">{{ $t(`mobileMenuTitle.rewards`) }}</span>-->
            <!--            </v-btn>-->
<!--            <v-btn-->
<!--              plain-->
<!--              class="reward-tab mr-2 inactive-btn"-->
<!--              :class="this.$route.name === routeName.CARD_MANIA ? 'active-btn' : 'inactive-btn'"-->
<!--              :to="{ name: routeName.CARD_MANIA, params: { lang: this.$route.params.lang } }"-->
<!--            >-->
<!--              <v-img :src="`/static/image/icon/dailyCheckInIcon.svg`" height="20" width="20" class=""></v-img>-->
<!--              <span class="pl-2">{{ $t(`label.cardMania`) }}</span>-->
<!--            </v-btn>-->
          </div>
        </v-col>
      </v-row>
      <v-card-text class="referralContainer">
        <router-view></router-view>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { errorCodeHelper, locale, uiHelper } from '@/util'
import { REFERRAL_GET_OVERVIEW } from '@/store/referral.module'
import { MEMBER_DETAIL } from '@/store/member.module'
import {
  REWARD_ACCEPT_CHALLENGE,
  REWARD_CONFIGURATION_STATUS,
  REWARD_GET_CHECK_IN_DAY,
  REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST,
  REWARD_GET_MEMBER_CHALLENGE,
  REWARD_GET_WALLET_LOG,
  REWARD_POST_CHECK_IN_DAY,
  REWARD_RESET_POST_CHECK_IN_DAY
} from '@/store/reward.module'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import { WHEEL_MODULE } from '@/store/wheel.module'

export default {
  name: 'rewardStore',
  data: () => ({
    dates: ['', ''],
    dayOfWeekNumber: new Date().getDay(),
    days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
    dailyRewards: [],
    dailyRewardsDialogShow: false,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    routeName: ROUTE_NAME
  }),
  computed: {
    memberChallenge() {
      return this.$store.state.reward.memberChallenge
    },
    rewardCheckInDay() {
      return this.$store.state.reward.checkInDay
    },
    checkInDayResponse() {
      return this.$store.state.reward.checkInDayResponse.complete
    },
    dailyCheckInPrizeList() {
      return this.$store.state.reward.dailyCheckInPrizeList
    },
    currentRouteName() {
      return this.$route.name
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    dailyCheckInPrizeList() {
      this.dailyRewards = this.$store.state.reward.dailyCheckInPrizeList[0].daily_coins_given
    },
    checkInDayResponse() {
      if (this.$store.state.reward.checkInDayResponse.complete) {
        this.checkInComplete(this.$store.state.reward.checkInDayResponse)
      }
    }
  },
  created() {
    this.getDailyCheckInPrizeList()
    this.getGrabCoinSetting()
    this.getRewardWalletLog()
    this.getRewardCheckInDay()
    this.getChallengeByMember()
    this.getMemberDetail()
    this.getWOF()
    this.getConfigurationStatus()
    this.directPopUpCheckInPopUp()
  },
  methods: {
    acceptChallenge() {
      let obj = {
        id: 2
      }
      this.$store.dispatch(`${REWARD_ACCEPT_CHALLENGE}`, { obj })
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    getChallengeByMember() {
      if (this.isLoggedIn) this.$store.dispatch(`${REWARD_GET_MEMBER_CHALLENGE}`)
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, { filter })
    },
    getDailyCheckInPrizeList() {
      let obj = {
        id: 2
      }
      this.$store.dispatch(`${REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST}`, { obj })
    },
    openDailyRewardsDialog() {
      if (this.memberChallenge.fk_challenge_id != 2) {
        this.acceptChallenge()
      }
      this.dailyRewardsDialogShow = true
    },
    getCoinsImg(reward) {
      let img = '/static/svg/coin_checked.svg'
      let img2 = '/static/svg/coin_unchecked.svg'
      let img3 = '/static/svg/coin.svg'
      if (this.rewardCheckInDay.includes(reward.day)) {
        return '/static/svg/coin_checked.svg'
      } else {
        if (reward.day < this.dayOfWeekNumber && reward.day != 0) {
          return '/static/svg/coin_unchecked.svg'
        } else {
          return '/static/svg/coin.svg'
        }
      }
    },
    directPopUpCheckInPopUp() {
      if (this.$route.params.tab === 'dailyCheckIn') {
        this.openDailyRewardsDialog()
      }
    },
    checkInDay() {
      let obj = {
        checkinDay: this.dayOfWeekNumber
      }
      this.$store.dispatch(`${REWARD_POST_CHECK_IN_DAY}`, { obj })
    },
    checkInComplete(res) {
      if (res.success) {
        this.$parent.openAlert(true, locale.getMessage(`message.checkedIn`))
        this.getMemberDetail()
        this.getRewardCheckInDay()
        this.getRewardWalletLog()
        this.closeDailyRewardsDialog()
      } else {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(res.code))
        // this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(res.code))
        this.getRewardCheckInDay()
        this.getRewardWalletLog()
        this.closeDailyRewardsDialog()
      }
      console.log('reach reset')
      this.$store.dispatch(`${REWARD_RESET_POST_CHECK_IN_DAY}`)
    },
    getRewardCheckInDay() {
      this.$store.dispatch(`${REWARD_GET_CHECK_IN_DAY}`)
    },
    closeDailyRewardsDialog() {
      this.dailyRewardsDialogShow = false
    },
    getRewardWalletLog() {
      let obj = {
        size: 20,
        page: this.selectedPage,
        startTime: this.dates[0],
        endTime: this.dates[1]
      }
      this.$store.dispatch(`${REWARD_GET_WALLET_LOG}`, { obj })
    },
    getMemberDetail() {
      if (this.isLoggedIn) this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    goToRewardHistory() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS,
        params: { lang: this.$route.params.lang }
      })
    },
    naviToTier() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_TIER,
        params: { lang: this.$route.params.lang }
      })
    },
    naviToSummary() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_SUMMARY,
        params: { lang: this.$route.params.lang }
      })
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    }
  }
}
</script>

<style lang="scss">
.scrollable-container {
  background-color: #f3f5f6;
  border-radius: 25px;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  .active-btn {
    background-color: #ffce01;
    border-radius: 25px;
  }
}

.reward-tab {
  flex: 0 0 auto;
}
.goRewardHistory {
  color: #0c8ce9;
  cursor: pointer;
}
.mobileRewardContainer {
  background-color: #f3f5f6;
  border-radius: 25px;
}
.rewardText {
  font-size: 25px;
}
.rewardCoinsBorder {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  .rewardCoinsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 206, 1, 1);
  }
  .rewardCoinsTextContainer {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
.rewardCoinsBorderMobile {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  .rewardCoinsContainerMobile {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 206, 1, 1);
  }
  .rewardCoinsTextContainerMobile {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
.afterLogin {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.afrLoginContainer {
  box-shadow: none !important;
  background-color: transparent !important;
}
.inactive-btn {
  background-color: #f3f5f6;
  border-radius: 25px;
}
.mobile-refLayout-before-login-card.v-card {
  background-color: #1d1d1d;
  text-align: center;
  display: flex;
  height: 170px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 10px;
  color: #ffffff;

  .beforeLoginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .inpage-before-login-first {
    color: black;
    background-color: var(--v-newMobilePrimary-base);
    border-radius: 15px;
    height: 50px;
  }

  .inpage-navigator-before-login-second {
    color: white;
    background-color: var(--v-newMobileSecondary-base);
    border-radius: 15px;
    height: 50px;
  }
  .v-card__subtitle {
    color: #ffffff;
  }

  .mobile-beforeLogin-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.referralCode-card {
  border-radius: 15px !important;
  border: 1px dashed #1d1d1d !important;
}

.share-card {
  background-color: #0066d1 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .theme--light.v-card {
    color: #ffffff;
  }

  border-radius: 15px !important;
}

.mobile-header {
  background-color: #000000;
  color: white;
  height: 50px;
  .mobile-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      border-bottom: 5px solid var(--v-newMobilePrimary-base);
      color: var(--v-newMobilePrimary-base);
    }
  }
}

@media (max-width: 959px) {
  .bank-mid {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
  .margin-desktop {
    width: 100%;
    margin: unset;
  }
}

.embedded-button {
  background-color: var(--v-newMobileSecondary-base);
  min-height: 40px;
  width: 130px !important;
  padding: 4px !important;
  text-transform: none !important;
}

.inner-banner-badge {
  width: 100%;
  height: 100%;
}
.topLeft-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  left: -20px;
  top: -30px;
  z-index: 1;
}

.bottomLeft-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  left: -50px;
  bottom: -65px;
  z-index: 1;
}

.topRight-coin {
  position: absolute;
  width: 55px;
  height: 50px;
  right: 50px;
  top: -30px;
  z-index: 1;
}

.bottomRight-coin {
  position: absolute;
  width: 70px;
  height: 70px;
  right: -20px;
  bottom: -30px;
  z-index: 1;
}

.newReferralBanner-desktop {
  max-height: 160px;
  border-radius: 10px;

  .rewardStoreBannerText {
    display: flex;
    justify-content: start;
    height: 100%;
    padding-left: 12px;
    flex-direction: column;
    font-size: 0.85rem;
    padding-bottom: 5px;
    color: #fff;
  }
}

.desktop_referral_card {
  border-radius: 12px !important;
  height: 100% !important;
  font-family: 'Roboto', sans-serif;
  margin-left: 16px;
  overflow-y: unset;

  .referral-header-content {
    color: #ffffff;
  }
  .referral-subtitle {
    margin-top: 16px;
    font-weight: bold;
  }
  .reward-tab {
    text-transform: capitalize;
    font-family: 'Roboto-Medium', sans-serif;
    color: #000;
    margin-right: 8px;
  }

  .v-divider--vertical {
    vertical-align: bottom !important;
  }

  .active-btn {
    background-color: var(--v-newMobilePrimary-base);
    color: #000;
    border-radius: 25px;
  }
}

.referralCode-card {
  border-radius: 15px !important;
  border: 1px dashed #1d1d1d !important;
}

.share-card {
  background-color: #0066d1 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .theme--light.v-card {
    color: #ffffff;
  }
  border-radius: 15px !important;
}
.game-menu-image {
  background-color: #ffffff;
  -webkit-mask-image: var(--src);
  mask-image: var(--src);
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-left: 8px;
}
</style>
